<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <b-form-group id="input-group-2" label="ชื่อจริง:" label-for="input-2">
                        <b-form-input id="input-1" v-model="firstname" placeholder="กรอกชื่อ"
                            required></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="นามสกุล:" label-for="input-2">
                        <b-form-input id="input-2" v-model="lastname" placeholder="กรอกนามสกุล"
                            required></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-1" label="ที่อยู่อีเมล:" label-for="input-1" description="">
                        <b-form-input id="input-3" v-model="email" type="email" placeholder="กรอกอีเมล"
                            required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-3" label="ระดับการเข้าถึงข้อมูล:" label-for="input-3">
                        <b-form-select id="input-4" v-model="role" :options="roles" required></b-form-select>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="รหัสผ่าน:" label-for="input-2">
                        <b-form-input id="input-5" type="password" v-model="password"
                            placeholder="กรอกรหัสผ่าน" ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="ยืนยันรหัสผ่าน:" label-for="input-2">
                        <b-form-input id="input-6" type="password" v-model="confirmPassword"
                            placeholder="กรอกยืนยันรหัสผ่าน"></b-form-input>
                    </b-form-group>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(saveUser())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import userService from "../../../../../services/user.service";
import roleService from "../../../../../services/role.service";
import { validEmail } from "../../../../../helpers/validation";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            userId: null,
            firstname: null,
            lastname: null,
            email: null,
            role_id: null,
            password: null,
            confirmPassword: null,
            roles: [],
            show: true,
            role: null,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
                {
                    text: "การตั้งค่า",
                    href: "#",
                },
                {
                    text: "ผู้ใช้งาน",
                    to: { name: "users" },
                },
                {
                    text: "เพิ่มข้อมูลผู้ใช้งาน",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getRoles: async function () {
            this.roles = [];
            let response = await roleService.getRoles();
            let roles = response.data.roles;
            this.roles.push({ text: "เลือกสิทธิ์การเข้าถึง", value: null })
            roles.forEach((item) => {
                let newR = {
                    text: item.name,
                    value: item.id
                }
                this.roles.push(newR);
            });
        },
        saveUser: async function () {
            this.isLoading = true;
            if (this.isValidate()) {
                let newR = {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    role_id: this.role,
                    password: this.password,
                    confirm_password: this.password
                }
                await userService.addUser(newR);
                history.back();
            } else {
                this.isLoading = false;
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }

        },
        isValidate() {
            this.errors = [];
            if (!this.firstname) {
                this.errors.push("Firstname required.");
            }
            if (!this.lastname) {
                this.errors.push("Lastname required.");
            }

            if (!this.password || !this.confirmPassword) {
                this.errors.push("Password required.");
            }

            if (!this.password == this.confirmPassword) {
                this.errors.push("Passwords are not match.");
            }
            if (!this.email) {
                this.errors.push("Email required.");
            } else if (!validEmail(this.email)) {
                this.errors.push("Valid email required.");
            }
            if (this.errors.length === 0) {
                return true;
            }
            return false;
        },

    },
    mounted() {
        this.getRoles();
    }

};
</script>